<template>
  <div>
    <Datatable ref="datatable" route="/produit/liste" :fields="fields" :data_filters="filters" titre="Liste des produits" :sort="sort">      
      <template #customField>
        <b-row>
          <b-col md="2">
            <b-form-group label="Ref">
              <Input type="number" v-model="filters.id"/> 
            </b-form-group>
          </b-col>
          <b-col md="5">
            <b-form-group label="Par mutuelle">
              <Input type="select-model" v-model="filters.mutuelle_id" :options="params.Mutuelle" /> 
            </b-form-group>
          </b-col>
          <b-col md="5">
            <b-form-group label="Par produit">
              <Input type="select-model" v-model="filters.produit" :options="params.Produit" /> 
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group label="Par formule">
              <Input type="select-model" v-model="filters.formule" :options="params.Formule" /> 
            </b-form-group>
          </b-col>
        </b-row>
      </template> 
      <template #action>
        <!-- <b-button v-b-tooltip.hover title="Creer un nouveau client" variant="success" class="btn-icon" href="/#/prospect/nouveau"><i class="fas fa-plus"></i></b-button> -->
      </template>
      <template #cell(id)="data">
        <router-link :to="'/produit/detail/'+data.value" target="_blank">{{data.value}}</router-link>
      </template>      
    </Datatable>
  </div>
</template>
<script>
import Datatable from '@/components/Datatable';
import Input from '@/components/InputForm';
export default {
  name: "ClientListe",
  components: {
    Datatable, Input
  },
  watch:{    
  },
  data(){
    return{
      filters:{
        id:null,
        mutuelle_id: [],
        produit: [],
        formule: []
      },
      fields: [
        {
          key: 'id',
          label: 'Ref',
          sortable:false,
        },
        {
          key: 'mutuelle',
          label: 'Mutuelle',
          sortable:true,
        },
        {
          key: 'produit',
          label: 'Produit',
          sortable:false,
        },
        {
          key: 'telephone_2',
          label: 'Telephone 2',
          sortable:false,
        },
        {
          key: 'formule',
          label: 'Formule',
          sortable:false,
        }              
      ],      
      sort:{
        key: 'id',
        value: 'DESC'
      },
      params:{
        Mutuelle:[],
        Produit: [],
        Formule: []
      }
    }
  },
  computed:{
  },
  methods: {
  },
  beforeMount() {
  },
  mounted() {  
    setTimeout(() => {
      this.params = this.$refs.datatable.datatable.meta;
    },1000);
  }
}
</script>
<style>
</style>
